import React from 'react';
import { card, services, servicesGrid } from './services.module.css';
import code from '../assets/images/code.webp';
import desktop from '../assets/images/desktop.webp';
import mobile from '../assets/images/mobile.webp';
import web from '../assets/images/web.webp';

export default function Services() {
    return (
        <section className={services} id="servicios">
            <h3>Nuestros <span className="text-green">servicios</span></h3>
            <p>Ofrecemos servicios para poder cubrir tus necesidades y desarrollar una solución adecuada para tu empresa</p>
            <div className={servicesGrid}>
                <div className={card}>
                    <img src={mobile} alt="teléfono" />
                    <h4 className='card-title'>Apps móviles</h4>
                    {/* <p className="card-description">Desarrollo de aplicaciones móviles.<br />Lorem ipsum </p> */}
                </div>
                <div className={card}>
                    <img src={web} alt="página web" />
                    <h4 className="card-title">Páginas web</h4>
                    {/* <p className="card-description">Desarrollo de Páginas web. <br />Lorem ipsum </p> */}
                </div>
                <div className={card}>
                    <img src={code} alt="editor de código" />
                    <h4 className="card-title">Aplicaciones web</h4>
                    {/* <p className="card-description">Desarrollo de Páginas web. <br />Lorem ipsum </p> */}
                </div>
                <div className={card}>
                    <img src={desktop} alt="escritorio" />
                    <h4 className="card-title">Aplicaciones de escritorio</h4>
                    {/* <p className="card-description">Desarrollo de aplicaciones de escritorio <br />Lorem ipsum </p> */}
                </div>
            </div>
        </section>
    );
}