import React from 'react';
import { contact, contactForm, contactInfo, form, glassBackground, laptopBg } from './contact-form.module.css';
import HsForm from './hs-form';
import laptop from '../assets/images/laptop.webp';
import phone from '../assets/images/phone.webp';
import letter from '../assets/images/letter.webp';

export default function ContactForm() {

    return (
        <div className={contact} id="contactanos">
            <div className={glassBackground}></div>
            <div className={contactForm}>
            
                <div className={form}>
                    <h3><span className="text-green">Cuéntanos</span> sobre tu proyecto</h3>
                    <p>Estamos para ayudarte en todo momento</p>
                    <HsForm />
                    <div className={contactInfo}>
                        <img src={phone} alt="teléfono" />
                        {/* <a href="tel:+5219994457716" className='numberFont'>+52 1 999 445 7716</a> */}
                        <a href="tel:+529993229085" className='numberFont'>+52 999 322 9085</a>
                    </div>
                    <div className={contactInfo}>
                        <img src={letter} alt="correo electrónico" />
                        <a href='mailto:contacto@deepdatat.com'>contacto@deepdatat.com</a>
                    </div>
                </div>
                <div className={laptopBg}>
                    <img src={laptop} alt="laptop" />
                </div>
            </div>
        </div>
    );
}