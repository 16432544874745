import React, { useEffect } from 'react';
import { useState } from 'react';
import { fieldLoading, labelLoading, textareaLoading } from './hs-form.module.css';

const configuration = process.env.NODE_ENV === 'production' ? 
{
    region: "na1",
    portalId: "23363646",
    formId: "c0597b6d-d230-461a-bef0-90f33df0528c"
} : {
    region: "na1",
    portalId: "21029475",
    formId: "64d6a4d3-edac-442f-aa35-89f11221eba6",
};

export default function HsForm() {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const script = document.createElement('script');
        script.src='https://js.hsforms.net/forms/embed/v2.js';
        script.async = true;
        document.body.appendChild(script);
  
        script.addEventListener('load', () => {
           // @TS-ignore
        if (window.hbspt) {
            window.jQuery = window.jQuery || function(nodeOrSelector) {
                if (typeof(nodeOrSelector) == 'string') {
                    return document.querySelector(nodeOrSelector);
                }
                return nodeOrSelector;
            };
            setLoading(false);
            // @TS-ignore
            window.hbspt.forms.create({
                ...configuration,
                target: '#hubspotForm',
                onBeforeFormInit: () => {
                // }
                // onFormReady: () => {
                    setFormStyle();
                    setLoading(true);
                },
            });
        }
        });
        return () => document.body.removeChild(script);
    }, []);

    return (
        <>
            {!loading ? 
            <div className="form-loading">
                <div className={labelLoading}></div>
                <div className={fieldLoading}></div>
                <div className={labelLoading}></div>
                <div className={fieldLoading}></div>
                <div className={labelLoading}></div>
                <div className={`${fieldLoading} ${textareaLoading}`}></div>
            </div>
             : null}
            <div id="hubspotForm" style={ !loading ? { display: 'none'} : { margin: '2rem 0'}}></div>
        </>
    );
}

function setFormStyle() {
    const iframe = document.querySelector('#hubspotForm>iframe');
    const style = document.createElement('style');
    style.textContent =
    `
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;800&family=Raleway:wght@100;400;800&display=swap');

    .bg-color {
        /*background-color: blue!important;*/
    }
    body {
        /*background-color: green;*/
        font-family: 'Montserrat', sans-serif !important;
    }
    textarea {
        resize: none;
        height: 150px!important;
    }
    input:not([type=submit]), textarea {
        background-color: white!important;
        font-family: 'Montserrat', sans-serif !important;
        padding: 10px;
        min-height: 150px;
        height: 60px;
    }
    input[type=submit] {
        width: 100%;
        padding: 20px;
        border-radius: 50px;
        background-color: #00CBAA;
        border: none;
        font-size: 18px;
        font-weight: 500;
        font-family: 'Montserrat', sans-serif !important;
    }
    label {
        font-family: 'Montserrat', sans-serif !important;
    }
    input[type=submit]:hover {
        background-color: #1fdcbd;
    }
    input[type=submit]:active, input[type=submit]:focus {
        background-color: #0fbda0;
    }
    .hs-submit+* {
        display: none;
    }
    p {
        text-align: center;
    }
    `;
    let content = iframe?.contentDocument || iframe?.contentWindow;
    if (!iframe || !content) return;

    if (content.document) content = content.document;
    content.head.appendChild(style);
}