import React from 'react';
import Layout from '../components/layout';
import Heroe from '../components/hero';
import OurWork from '../components/our-work';
import Tools from '../components/tools';
import Clients from '../components/clients';
import ContactForm from '../components/contact-form';
import logo from '../assets/images/logoWhite.webp';

export default function IndexPage() {
  return (
    <Layout>
        <Heroe />
        <OurWork />
        <Tools />
        <Clients />
        <ContactForm />
    </Layout>
  );
}

// export const Head = () => <title>Deep Data</title>;
export const Head = () => (
  <>
    <title>Deep Data Technologies | soluciones tecnológicas</title>

    <meta charSet="utf-8"/>
    <meta name="description" content="Proveemos soluciones tecnológicas para optimizar los procesos clave de tu empresa" />    
    {/* <meta name="google-site-verification" content="+nxGUDJ4QpAZ5l9Bsjdi102tLVC21AIh5d1Nl23908vVuFHs="/> */}
    <meta name="robots" content="index,follow"/>

    {/* facebook, ... */}
    <meta property="og:url"                content="https://deepdatat.com/" />
    <meta property="og:type"               content="website" />
    <meta property="og:title"              content="Deep Data Technologies" />
    <meta property="og:description"        content="Proveemos soluciones tecnológicas para optimizar los procesos clave de tu empresa" />
    <meta property="og:image"              content={ logo } />
    <meta property="og:locale"              content="es_MX" />

    <meta httpEquiv="content-language" content="es_MX"/>
    <link rel="alternate" hrefLang="es_MX" />
    <link rel="canonical" href="https://deepdatat.com/" />
    
    <script type="text/javascript">
      {`
        (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "hf7ba6ft8q");
      `}
    </script>

    <script type="application/ld+json">
        {`
          {
            "@context":"http://schema.org/",
            "@type": "Organization",
            "name": "Deep Data Technologies",
            "telephone": "+52 999 322 9085",
            "address":"C. 18 x 15 y 17 Cholul, Mérida, Yucatán, México",
            "email":"contacto@deepdatat.com"
          }
        `}
      </script>

  </>
)