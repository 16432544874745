import React from 'react';
import { step, process, ourWork } from './our-work.module.css';
import document from '../assets/images/document.webp';
import tasklist from '../assets/images/tasklist.webp';
import desktop from '../assets/images/desktop.webp';
import save from '../assets/images/save.webp';

export default function OurWork() {
    return (
        <section className={ourWork}>
            <h3>Nuestra <span className="text-green">forma de trabajo</span></h3>
            <p>En nuestro proceso de trabajo vamos de la mano contigo para cubrir todas tus necesidades</p>
            <div className={process}>
                <div className={step} data-step-number="1">
                    <img src={document} alt="documento" />
                    {/* <span>1</span> */}
                    <h4 data-work-number="1">Análisis y propuesta</h4>
                    <p>
                        Escuchamos y analizamos tus 
                        requisitos  para ofrecerte una 
                        solución
                    </p>
                </div>
                <div className={step} data-step-number="2">
                    <img src={tasklist} alt="lista de tareas" />
                    <h4 data-work-number="2">Planificación</h4>
                    <p>
                        Creamos un plan de trabajo 
                        dividida en fases adaptadas al 
                        alcance del proyecto
                    </p>
                </div>
                <div className={step} data-step-number="3">
                    <img src={desktop} alt="escritorio" />
                    <h4 data-work-number="3">Desarrollo y prueba</h4>
                    <p>
                        Trabajamos en tu proyecto y 
                        realizamos pruebas para asegurarnos
                        que cumplimos con los requisitos
                    </p>
                </div>
                <div className={step} data-step-number="4">
                    <img src={save} alt="guardado" />
                    <h4 data-work-number="4">Entregables</h4>
                    <p>
                        Te entregamos tu software
                        con toda la documentación
                        generada durante el desarrollo
                    </p>
                </div>
            </div>
        </section>
    );
}