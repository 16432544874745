import React from 'react';
import Services from './services';
import { btnContact, circle, circleGroup, container, description, hero, moveDown, moveUp } from './hero.module.css';

const circles = [
    { backgroundColor: '#699ED24D', width: 173, height: 173, left: -130, top: 80 },
    { backgroundColor: '#699ED275', width: 116, height: 116, left: 10, top: 400 },
    { backgroundColor: '#00CBAA33', width: 158, height: 158, left: -100, top: 1100 },
    { backgroundColor: '#699ED275', width: 62, height: 62, left: '40%', top: 260 },
    { backgroundColor: '#699ED21A', width: 172, height: 172, left: '45%', top: 320 },
    { backgroundColor: '#699ED2EB', width: 95, height: 95, left: '60%', top: 370 },
    { backgroundColor: '#699ED2E0', width: 62, height: 62, right: 150, top: 80 },
    { backgroundColor: '#00CBAA6B', width: 208, height: 208, right: 50, top: 200 },
    { backgroundColor: '#699ED280', width: 208, height: 208, right: -140, top: 520 },
];

export default function Heroes() {

    return (
        <section className={hero} id="hero">
            <div className="circles">
                {[...Array(3).keys()].map((_, i) => printCircles(i))}
            </div>
            <div className={container}>
                <div className={description}>
                    <h1>
            Descubre el verdadero <br />potencial de tu <span className="text-green">negocio</span>
                    </h1>
                    <p>Proveemos soluciones tecnológicas para optimizar <br /> los procesos clave de tu empresa</p>
                    <a href='#contactanos' className={'btn btn-pill ' + btnContact}>Contáctanos</a>
                </div>
                <Services />
            </div>
        </section>
    );
}

function printCircles(index) {
    const i = index * 3;
    return (
    <div className={`${circleGroup} ${(index % 2) === 0 ? moveUp : moveDown}`} key={index}>
        <div className={circle} style={circles[i]}></div>
        <div className={circle} style={circles[i + 1]}></div>
        <div className={circle} style={circles[i + 2]}></div>
    </div>
    );
}