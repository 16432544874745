import React from 'react';
import { tools, list } from './tools.module.css';
import angularToolLogo from '../assets/images/angularToolLogo.webp';
import nodeToolLogo from '../assets/images/nodeToolLogo.webp';
import awsToolLogo from '../assets/images/awsToolLogo.webp';
import cSharpToolLogo from '../assets/images/cSharpToolLogo.webp';
import hubspotToolLogo from '../assets/images/hubspotToolLogo.webp';

export default function Tools() {
    return (
        <div className={tools}>
            <h3 className="text-green">Herramientas</h3>
            <p>Trabajamos con las mejores herramientas eligiendo cuidadosamente las adecuadas para tu proyecto</p>
            <div className={list}>
                <img height="150px" width="auto" src={angularToolLogo} alt="angular" />
                <img height="150px" width="auto" src={nodeToolLogo} alt="node" />
                <img height="150px" width="auto" src={awsToolLogo} alt="aws" />
                <img height="150px" width="auto" src={cSharpToolLogo} alt="c#" />
                <img height="150px" width="auto" src={hubspotToolLogo} alt="hubspot" />
            </div>
        </div>
    );
}