import React from 'react';
import { clients, list, card } from './clients.module.css';
import lage from '../assets/images/lage.webp';
import growx from '../assets/images/growx.webp';
import vine from '../assets/images/vine.webp';

export default function Clients() {
    return (
        <div className={clients}>
            <h3>Nuestros <span className="text-green">clientes</span></h3>
            <p>Cada cliente tiene sus propias necesidades y les ayudamos a elegir la mejor solución para explotar su potencial</p>
            <div className={list}>
                <div className={card}>
                    <img src={lage} height="96px" width="auto" alt="lage" />
                    <h4>Aplicación web</h4>
                    <p>
                        Software administrativo interno de consulta
                        de inventarios de proveedores externos 
                        y creación de cotizaciones
                    </p>
                </div>
                <div className={card}>
                    <img src={growx} height="96px" width="auto" alt="growX" />
                    <h4>Páginas web</h4>
                    <p>
                        Desarrollo de módulos para hubspot y 
                        wordpress 
                    </p>
                </div>
                <div className={card}>
                    <img src={vine} height="96px" width="auto" alt="vine" />
                    <h4>Aplicación web, escritorio y movil</h4>
                    <p>
                        Sistema administrativo para 
                        producción de fábrica
                    </p>
                </div>
            </div>
        </div>
    );
}